<template>
  <div class="client">
    <!-- 搜索 -->
    <van-search
      v-model="searchValue"
      show-action
      placeholder="搜索客户姓名或客户电话"
      maxlength="11"
      @clear="onSearch"
      @search="onSearch"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <van-tabs v-model="active" @change="handleChangeTab" swipeable>
      <van-tab title="未联系" name="0">
        <p v-if="clientList.length === 0">暂无数据...</p>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
          :offset="10"
        >
          <div v-for="(client, index) in clientList" :key="index">
            <van-row>
              <van-col span="24">
                ID：
                <span>{{ client.number }}</span>
              </van-col>
              <van-divider />
              <van-col span="12">
                客户姓名：
                <span>{{ client.name }}</span>
              </van-col>
              <van-col span="12">
                客户电话：
                <span>{{ client.mobile }}</span>
              </van-col>
              <van-col span="24">
                客户渠道：
                <span>{{ client.place }}</span>
              </van-col>
            </van-row>
            <van-row class="client-botton-item">
              <van-col span="24" @click="callPhone(client.mobile)">
                <img src="../../../assets/client_phone.png" alt />拨打电话
              </van-col>
              <van-col
                span="12"
                v-for="(item, index) in noContactedText"
                :key="index"
              >
                <van-button
                  round
                  size="small"
                  class="client-botton"
                  type="primary"
                  @click="getFollowInfo(item, client.id, client.state)"
                  >{{ item }}</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
      <van-tab title="已联系" name="1">
        <p v-if="clientList.length === 0">暂无数据...</p>
        <van-list
          v-else
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div v-for="(client, index) in clientList" :key="index">
            <van-row>
              <van-col span="24">
                ID：
                <span>{{ client.number }}</span>
                <span class="client-right">
                  跟进次数：
                  <span id="count-text">{{ client.count }}</span>
                </span>
              </van-col>
              <van-divider />
              <van-col span="12">
                客户姓名：
                <span>{{ client.name }}</span>
              </van-col>
              <van-col span="12">
                客户电话：
                <span>{{ client.mobile }}</span>
              </van-col>
              <van-col span="24">
                客户渠道：
                <span>{{ client.place }}</span>
              </van-col>
            </van-row>
            <van-row class="client-botton-item">
              <van-col span="24" @click="callPhone(client.mobile)">
                <img src="../../../assets/client_phone.png" alt />拨打电话
              </van-col>
              <van-col
                span="12"
                v-for="(item, index) in contactedText"
                :key="index"
              >
                <van-button
                  round
                  size="small"
                  class="client-botton"
                  type="primary"
                  @click="getFollowInfo(item, client.id, client.state)"
                  >{{ item }}</van-button
                >
              </van-col>
            </van-row>
          </div>
        </van-list>
      </van-tab>
    </van-tabs>

    <!-- 修改客户信息 -->
    <van-popup v-model="showUpdateInfo" closeable @close="closeUpdateInfo">
      <div class="follow_header">
        <div></div>
        <span>修改客户信息</span>
      </div>
      <van-form @submit="onUpdateFollowInfo" :show-error="false">
        <van-field
          v-model="updateReason"
          rows="5"
          autosize
          type="textarea"
          maxlength="30"
          placeholder="请输入修改原因"
          show-word-limit
          :rules="[{ required: true, message: '请输入修改原因' }]"
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            :loading="btn_loading"
            loading-text="确认"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>

    <!-- 更换跟进人 -->
    <van-popup v-model="showReplacePerson" closeable @close="closeReplaceInfo">
      <div class="follow_header">
        <div></div>
        <span>更换跟进人</span>
      </div>
      <van-form @submit="replacePerson" :show-error="false">
        <van-field
          readonly
          clickable
          name="picker"
          :value="replacePersonValue"
          placeholder="请选择跟进人"
          @click="showPerson = true"
          :rules="[{ required: true, message: '请选择跟进人' }]"
          clearable
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            :loading="btn_loading"
            loading-text="确认"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>

    <!-- 跟进人员列表 -->
    <van-popup v-model="showPerson" position="bottom">
      <van-picker
        show-toolbar
        :columns="personList"
        @confirm="onPersonConfirm"
        @cancel="showPerson = false"
      />
    </van-popup>

    <!-- 客户签单 -->
    <van-popup
      v-model="showCustomerSign"
      closeable
      @close="closeCustomerSign"
      position="bottom"
      round
    >
      <div class="follow_header">
        <div></div>
        <span>客户签单</span>
      </div>
      <van-form
        @submit="onSubmitCustomerSign"
        label-width="70px"
        :show-error="false"
      >
        <van-field
          readonly
          clickable
          name="calendar"
          :value="customerSignForm.signDate"
          label="签单时间"
          placeholder="请选择签单时间"
          required
          :rules="[{ required: true, message: '请选择签单时间' }]"
          @click="showSignDatePicker = true"
        />
        <van-field
          v-model="customerSignForm.amount"
          name="签单金额"
          label="签单金额"
          placeholder="请输入签单金额（单位：元）"
          maxlength="8"
          required
          type="number"
          :rules="[{ required: true, message: '请输入签单金额' }]"
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="customerSignForm.stayDate"
          label="入住时间"
          placeholder="请选择入住时间"
          required
          :rules="[{ required: true, message: '请选择入住时间' }]"
          @click="showStayDatePicker = true"
        />
        <van-field
          v-model="customerSignForm.note"
          rows="2"
          autosize
          label="备注"
          type="textarea"
          maxlength="30"
          placeholder="请输入备注"
          show-word-limit
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            :loading="btn_loading"
            loading-text="确认"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 签单时间 -->
    <van-popup v-model="showSignDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="min_date"
        @confirm="onConfirmSignDatePicker"
        @cancel="showSignDatePicker = false"
      />
    </van-popup>
    <!-- 入住时间 -->
    <van-popup v-model="showStayDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmStayDatePicker"
        @cancel="showStayDatePicker = false"
      />
    </van-popup>

    <!-- 客户参观记录 -->
    <van-popup v-model="showAttendRecord" closeable @close="closeAttendRecord">
      <div class="follow_header">
        <div></div>
        <span>客户参观记录</span>
      </div>
      <van-form
        @submit="onSubmitAttendRecord"
        label-width="80px"
        :show-error="false"
        style="height: 220px"
      >
        <van-field
          readonly
          clickable
          name="picker"
          :value="attendRecordForm.pepole"
          label="跟进人员"
          placeholder="请选择跟进人"
          @click="showAdminAndFollow = true"
          :rules="[{ required: true, message: '请选择跟进人' }]"
          clearable
          required
        />
        <van-field
          readonly
          clickable
          name="calendar"
          :value="attendRecordForm.visitDate"
          label="参观时间"
          placeholder="请选择参观时间"
          required
          :rules="[{ required: true, message: '请选择参观时间' }]"
          @click="showVisitDatePicker = true"
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            :loading="btn_loading"
            loading-text="确认"
            >确认</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 参观记录跟进人员 -->
    <van-popup v-model="showAdminAndFollow" position="bottom">
      <van-picker
        show-toolbar
        :columns="adminAndFollowsonList"
        @confirm="onAdminAndFollowConfirm"
        @cancel="showAdminAndFollow = false"
      />
    </van-popup>
    <!-- 参观时间 -->
    <van-popup v-model="showVisitDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="datetime"
        title="选择年月日时分"
        :min-date="minDate"
        @confirm="onConfirmVisitDatePicker"
        @cancel="showVisitDatePicker = false"
      />
    </van-popup>

    <!-- 跟进记录 -->
    <van-popup
      v-model="showFollowRecord"
      closeable
      id="followRecord"
      position="bottom"
      round
    >
      <div class="follow_header">
        <div></div>
        <span>跟进记录</span>
      </div>
      <!-- 跟进记录 -->
      <div class="follow-record-list">
        <div
          class="follow-record-item"
          v-for="(item, index) in fllowUpRecords"
          :key="index"
        >
          <div class="follow-record-count">{{ index + 1 }}</div>
          <van-row>
            <van-col span="24">
              下次跟进日期：
              <span>{{ item.time }}</span>
            </van-col>
            <van-col span="24">
              本次跟进日期：
              <span>{{ item.createtime }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 23px">
              跟进人员：
              <span>{{ item.name }}</span>
            </van-col>
            <van-col span="24" style="padding-left: 23px">
              跟进结果：
              <span>{{ item.desc }}</span>
            </van-col>
            <van-col
              span="24"
              style="padding-left: 23px"
              v-if="item.remarkdesc"
            >
              跟进备注：
              <span>{{ item.remarkdesc }}</span>
            </van-col>
          </van-row>
          <van-divider />
        </div>
        <div style="margin: 16px; margin-top: 40px">
          <van-button block type="info" @click="callPhone(fllowUpRecordMobile)"
            >现在跟进</van-button
          >
        </div>
      </div>
    </van-popup>

    <!-- 注销此客户 -->
    <van-popup
      v-model="showCancelCustomer"
      closeable
      @close="closeCancelCustomer"
    >
      <div class="follow_header">
        <div></div>
        <span>注销此客户</span>
      </div>
      <van-form
        @submit="onSubmitCancelCustomer"
        label-width="80px"
        :show-error="false"
      >
        <van-field
          v-model="currentDate"
          disabled
          autosize
          label="当前时间"
          show-word-limit
        />
        <van-field
          v-model="cancelReason"
          rows="3"
          autosize
          label="注销原因"
          type="textarea"
          maxlength="30"
          placeholder="请输入注销原因"
          show-word-limit
          required
          :rules="[{ required: true, message: '请输入注销原因' }]"
        />
        <div style="margin: 16px; margin-top: 40px">
          <van-button
            block
            type="info"
            native-type="submit"
            style="background-color: #ff6b54; border: #ff6b54"
            :loading="btn_loading"
            loading-text="注销"
            >注销</van-button
          >
        </div>
      </van-form>
    </van-popup>
    <!-- 全局加载 -->
    <van-overlay :show="isShow">
      <van-loading type="spinner" color="#CC9756" />
    </van-overlay>
  </div>
</template>
<script>
import {
  getMyClientList,
  getSelectOptionsList,
  getFollowList,
  contactlogs,
  updateCustomer,
  changeFollowPerson,
  orderconfirms,
  orderfinishlogs,
  ordervisitlogs,
  getFllowUpRecords,
  deleteCustomer,
  getAdminAndFollowList,
} from "@/api/api";
import moment from "moment/moment";
export default {
  data() {
    return {
      // 按钮加载
      btn_loading: false,
      // 全局加载
      isShow: true,
      // 搜索值
      searchValue: "",
      //tab选择
      active: 0,
      // tab方式
      tabType: 1,
      // 上拉加载
      loading: false,
      finished: false,
      page: 1, //请求第几页
      pageSize: 10, //每页请求的数量
      total: 0, //总共的数据条数
      // 客户ID
      customerId: "",
      // 未联系button文本
      noContactedText: [
        "跟进信息",
        "修改客户信息",
        "更换跟进人",
        "预约入园信息",
        "客户签单",
        "注销此客户",
      ],
      // 已联系button文本
      contactedText: [
        "跟进信息",
        "修改客户信息",
        "更换跟进人",
        "预约入园信息",
        "客户参观记录",
        "客户签单",
        "跟进记录",
        "注销此客户",
      ],
      // 客户列表数据
      clientList: [],
      // 时间列表显示与隐藏
      showPicker: false,
      minDate: new Date(),
      min_date: new Date(2020, 8, 9),
      currentDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
      // 修改客户信息弹框的显示与隐藏
      showUpdateInfo: false,
      // 修改原因
      updateReason: "",
      // 更换跟进人弹框的显示与隐藏
      showReplacePerson: false,
      // 跟进人
      replacePersonValue: "",
      // 跟进人员ID
      followUpId: "",
      // 跟进人列表显示与隐藏
      showPerson: false,
      // 跟进人员列表
      personList: [],
      followPersonList: [],
      // 客户签单弹框的显示与隐藏
      showCustomerSign: false,
      // 客户签单表单数据
      customerSignForm: {
        signDate: "",
        amount: "",
        stayDate: "",
        note: "",
      },
      // 签单时间控件
      showSignDatePicker: false,
      // 入住时间控件
      showStayDatePicker: false,
      // 客户参观记录弹窗的显示与隐藏
      showAttendRecord: false,
      attendRecordForm: {
        pepole: "",
        visitDate: "",
      },
      // 参观记录跟进人员列表显示与隐藏
      showAdminAndFollow: false,
      // 跟进人员列表
      adminAndFollowsonList: [],
      adminAndFollowsonLists: [],
      // 参观时间控件得显示与隐藏
      showVisitDatePicker: false,
      // 跟进记录弹窗的显示与隐藏
      showFollowRecord: false,
      // 跟进记录信息
      fllowUpRecords: [],
      // 跟进记录电话
      fllowUpRecordMobile: "",
      // 注销客户弹窗的显示与隐藏
      showCancelCustomer: false,
      //注销原因
      cancelReason: "",
    };
  },

  created() {
    // 获取客户列表
    this.getMyClientList(this.tabType);
    // 下拉列表选择
    this.getSelectOptionsList();
    // 获取跟进人员列表
    this.getFollowList();
  },

  methods: {
    // tab切换选中值
    handleChangeTab() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.clientList = [];
      if (this.active === "0") {
        this.tabType = 1;
        this.getMyClientList(this.tabType);
      } else {
        this.tabType = 2;
        this.getMyClientList(this.tabType);
      }
    },
    // 获取我的客户列表
    getMyClientList(type) {
      getMyClientList({
        type: type,
        search: this.searchValue,
        page: this.page,
      })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.length === 0) {
              this.isShow = false;
            }
            // 加载状态结束
            this.loading = false;
            if (this.clientList.length >= res.meta.total) {
              this.finished = true;
              return;
            } else {
              this.clientList = this.clientList.concat(res.data);
              this.page++;
            }
            this.isShow = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 搜索
    onSearch() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.clientList = [];
      this.getMyClientList(this.tabType);
    },
    // 加载更多
    onLoad() {
      this.getMyClientList(this.tabType);
    },
    // 拨打电话
    callPhone(phone) {
      window.location.href = "tel:" + phone;
    },
    // 下拉列表选择
    getSelectOptionsList() {
      getSelectOptionsList().then((res) => {
        if (res.code === 200) {
          this.appointElderAgeList = res.data.ages;
          this.appointHealthList = res.data.healthconditions;
          this.reservationNumberList = res.data.numbers;
        }
      });
    },
    // 点击按钮显示相关的弹窗
    getFollowInfo(text, id, state) {
      this.customerId = id;
      if (text === "跟进信息") {
        this.clientList.map((item) => {
          if (id === item.id) {
            this.$router.push({
              path: "/followInfo",
              query: { username: item.name, phone: item.mobile, id: id },
            });
          }
        });
      } else if (text === "修改客户信息") {
        this.showUpdateInfo = true;
      } else if (text === "更换跟进人") {
        this.showReplacePerson = true;
      } else if (text === "客户签单") {
        this.showCustomerSign = true;
      } else if (text === "预约入园信息") {
        this.$router.push({
          path: "/appointGarden",
          query: { id: id },
        });
      } else if (text === "注销此客户") {
        this.showCancelCustomer = true;
        this.currentDate = moment(new Date()).format("YYYY-MM-DD hh:mm:ss");
      } else if (text === "客户参观记录") {
        if (state === 3 || state === 4 || state === 6) {
          this.showAttendRecord = true;
          this.getAdminAndFollowList();
        } else {
          this.$toast("该客户还没有预约，添加失败！");
        }
      } else if (text === "跟进记录") {
        this.clientList.map((item) => {
          if (id === item.id) {
            this.fllowUpRecordMobile = item.mobile;
          }
        });
        getFllowUpRecords({ id: this.customerId }).then((res) => {
          if (res.code === 200) {
            this.fllowUpRecords = res.data;
            if (this.fllowUpRecords.length === 0) {
              this.$toast("暂无跟进记录");
            } else {
              this.showFollowRecord = true;
            }
          }
        });
      }
    },
    // 记录客户信息下次跟进日期选择
    onConfirmPicker(time) {
      this.followInfoData.date = moment(time).format("YYYY-MM-DD HH:mm:ss");
      this.showPicker = false;
    },
    // 提交修改原因
    onUpdateFollowInfo() {
      this.btn_loading = true;
      updateCustomer({
        id: this.customerId,
        type: 1,
        desc: this.updateReason,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.btn_loading = false;
            this.showUpdateInfo = false;
          }
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    // 关闭修改客户信息弹窗清空表单数据
    closeUpdateInfo() {
      this.updateReason = this.$options.data().updateReason;
    },
    // 获取跟进人员列表
    getFollowList() {
      this.personList = [];
      this.followPersonList = [];
      getFollowList().then((res) => {
        if (res.code === 200) {
          res.data.map((item) => {
            this.personList.push(item.name);
            this.followPersonList.push({ id: item.id, name: item.name });
          });
        }
      });
    },
    // 跟进人选择
    onPersonConfirm(value) {
      this.replacePersonValue = value;
      this.followPersonList.map((item) => {
        if (this.replacePersonValue === item.name) {
          this.followUpId = item.id;
        }
      });
      this.showPerson = false;
    },
    // 更换跟进人
    replacePerson() {
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.clientList = [];
      this.btn_loading = true;
      changeFollowPerson({
        id: this.followUpId,
        orderid: this.customerId,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.loading = false;
            this.showReplacePerson = false;
            this.getMyClientList(this.tabType);
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 关闭更换跟进人弹窗清空表单数据
    closeReplaceInfo() {
      this.replacePersonValue = this.$options.data().replacePersonValue;
      this.attendRecordForm.pepole = this.$options.data().attendRecordForm.pepole;
    },
    // 关闭客户签单弹窗清空表单数据
    closeCustomerSign() {
      this.customerSignForm = this.$options.data().customerSignForm;
    },
    // 客户签单
    onSubmitCustomerSign() {
      this.btn_loading = true;
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.clientList = [];
      orderfinishlogs({
        id: this.customerId,
        time: this.customerSignForm.signDate,
        price: this.customerSignForm.amount,
        checktime: this.customerSignForm.stayDate,
        desc: this.customerSignForm.note,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.btn_loading = false;
            this.showCustomerSign = false;
            this.getMyClientList(this.tabType);
          }
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    // 客户签单签单时间
    onConfirmSignDatePicker(time) {
      this.customerSignForm.signDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showSignDatePicker = false;
    },
    // 客户签单入住时间
    onConfirmStayDatePicker(time) {
      this.customerSignForm.stayDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showStayDatePicker = false;
    },
    // 关闭客户参观记录弹窗清空表单数据
    closeAttendRecord() {
      this.attendRecordForm = this.$options.data().attendRecordForm;
    },
    // 客户参观记录表单提交
    onSubmitAttendRecord() {
      this.btn_loading = true;
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.clientList = [];
      ordervisitlogs({
        id: this.followUpId,
        orderid: this.customerId,
        time: this.attendRecordForm.visitDate,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.btn_loading = false;
            this.showAttendRecord = false;
            this.getMyClientList(this.tabType);
          }
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    // 获取参观记录跟进人员列表
    getAdminAndFollowList() {
      getAdminAndFollowList().then((res) => {
        if (res.code === 200) {
          res.data.map((item) => {
            this.adminAndFollowsonList.push(item.name);
            this.adminAndFollowsonLists.push({ id: item.id, name: item.name });
          });
        }
      });
    },
    // 跟进人选择
    onAdminAndFollowConfirm(value) {
      this.attendRecordForm.pepole = value;
      this.adminAndFollowsonLists.map((item) => {
        if (this.attendRecordForm.pepole === item.name) {
          this.followUpId = item.id;
        }
      });
      this.showAdminAndFollow = false;
    },
    // 参观时间得选择
    onConfirmVisitDatePicker(time) {
      this.attendRecordForm.visitDate = moment(time).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      this.showVisitDatePicker = false;
    },
    // 关闭注销客户弹窗清空表单数据
    closeCancelCustomer() {
      this.cancelReason = this.$options.data().cancelReason;
    },
    //注销此客户表单提交
    onSubmitCancelCustomer() {
      this.btn_loading = true;
      this.page = 1;
      this.loading = false;
      this.finished = false;
      this.clientList = [];
      deleteCustomer({
        id: this.customerId,
        desc: this.cancelReason,
      })
        .then((res) => {
          if (res.code === 200) {
            this.$toast.success(res.data);
            this.btn_loading = false;
            this.showCancelCustomer = false;
            this.getMyClientList(this.tabType);
          }
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.client {
  margin-top: 22px;
}
.van-tabs {
  margin-top: 10px;
}
p {
  font-size: 14px;
  text-align: center;
  margin: 20px 0;
  color: #9a9a9a;
}
/deep/.van-tab--active {
  color: #f3973b;
  border-left: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}
/deep/.van-tabs__line {
  width: 50%;
  height: 4px;
  background-color: #f3973b;
}
.van-row:nth-child(1) {
  min-width: 100%;
  min-height: 115px;
  background-color: #fff;
  margin-bottom: 5px;
  font-size: 13px;
  .van-col:nth-child(1) {
    padding: 15px 0 10px 16px;
    color: #9a9a9a;
    font-size: 12px;
    div {
      font-size: 12px;
    }
    span {
      font-size: 12px;
    }
  }
  .client-right {
    float: right;
    margin-right: 16px;
  }
  .van-divider {
    border-color: #eaeaea;
    padding: 0 16px;
  }
  .van-col:last-child {
    padding-bottom: 15px;
  }
  .van-col {
    font-size: 13px;
    color: #9a9a9a;
    padding-bottom: 10px;
    padding-left: 16px;
    span {
      color: #000;
      font-size: 13px;
    }
  }
  .van-col:nth-child(4) {
    padding-left: 0;
  }
}
#count-text {
  color: #f3973b;
}
.client-botton-item {
  min-height: 168px;
  background-color: #fff;
  padding-bottom: 16px;
  margin-bottom: 10px;
  .van-col {
    text-align: center;
  }
  .van-col:nth-child(1) {
    font-size: 14px;
    display: flex;
    color: #f3973b;
    flex-direction: inherit;
    justify-content: center;
    margin: 23px 0 18px 0;
    img {
      width: 18px;
      height: 18px;
      margin-right: 10px;
    }
  }
  .client-botton {
    width: 158px;
    background-color: #f2f2f2;
    color: #343434;
    border: #343434;
    font-size: 12px;
    margin-bottom: 10px;
  }
}
.van-popup--center {
  border-radius: 10px;
  top: 50%;
  left: 5%;
  right: 5%;
  transform: translate3d(0, -50%, 0);
}
.van-popup {
  background: #fff;
  .follow_header {
    width: 100%;
    min-height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #eaeaea;
    display: flex;
    justify-content: flex-start;
    flex-direction: inherit;
    #followRecord {
      height: 345px;
      position: fixed;
      top: 0;
      background: #fff;
    }
    div {
      width: 4px;
      height: 20px;
      background: #f3983c;
      margin: 12px 8px 10px 12px;
    }
    span {
      font-size: 18px;
      color: #343434;
    }
  }
  .van-form {
    overflow: auto;
    max-height: 400px;
    .customer-info {
      display: flex;
      flex-direction: initial;
      justify-content: flex-start;
      color: #9a9a9a;
      span {
        font-size: 16px;
        padding: 16px 0 6px 21px;
      }
      span:nth-child(2) {
        color: #000;
        padding-left: 30px;
      }
    }
    .van-field {
      margin-top: 10px;
    }
    /* .stayin-reson-botton {
      background-color: #fff;
      border: 1px solid #eaeaea;
      color: #9a9a9a;
      margin: 8px;
    }
    .stayin-reson-active {
      background-color: #fff;
      border: 1px solid #f3973b;
      color: #f3973b;
      margin: 8px;
    }
    ::v-deep .van-field--disabled .van-field__label {
      color: #646566;
    } */
    /deep/.van-button--info,
    /deep/.van-button--primary {
      background-color: #f3973b;
      border: #f3973b;
    }
    .oneself-class {
      .van-button {
        color: #999999;
      }
    }
    .oneself-class-active {
      .van-button {
        color: #f3973b;
        background: #fef5eb;
      }
    }
  }
  .follow-record-list {
    overflow: auto;
    max-height: 400px;
    .follow-record-item {
      padding: 16px 16px 0 16px;
      .follow-record-count {
        width: 34px;
        height: 20px;
        line-height: 24px;
        border: 1px solid #f3983c;
        border-radius: 20px;
        text-align: center;
        color: #f3983c;
        font-size: 11px;
      }
      .van-row {
        margin-top: 10px;
        .van-col {
          color: #9a9a9a;
          font-size: 12px;
          line-height: 26px;
          span {
            color: #343434;
            font-size: 12px;
          }
        }
      }
    }
    .van-button {
      background-color: #f3973b;
      border: #f3973b;
    }
    .van-divider {
      margin-bottom: 0;
    }
  }
}
.van-overlay {
  z-index: 999;
}
</style>